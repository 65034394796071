import { createApp } from 'vue'
import App from './Login.vue'

import 'uikit/dist/css/uikit.css'

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons)

createApp(App).mount('#app')
